<template>
  <div 
    class="card grid-item" 
    :class="adaptiveStyleClasses"
  >
    <div class="card-body position-relative">
      <span
        class="label fw-bold"
        :class="textClass"
      >
        {{ labelText }}
      </span>
      <h1
        class="value"
        :class="textClass"
      >
        <template
          v-for="(value, index) in values"
          :key="index"
        >
          <value-tween
            :end-value="value"
            decimal-value
          />
          <template v-if="values.length > 1 && index < values.length - 1"> / </template>
        </template>
        
        <small
          v-if="showPercentageSymbol"
          class="percentage"
        >%</small>
      </h1>
    </div>
    
    <div class="icon-container">
      <i
        v-if="fontAwesomeIconName != null"
        class="icon font-awesome fas"
        :class="[ textClass, 'fa-' + fontAwesomeIconName ]"
      ></i>
    </div>
  </div>
</template>
  
<script>
import ValueTween from './ValueTween.vue';

export default {
  name: 'ValueCard',

  components: {
    ValueTween
  },

  props: {
    backgroundClass: { type: String, default: 'bg-primary' },
    textClass: { type: String, default: 'text-white' },
    labels: { type: Array, required: true },
    values: { type: Array, required: true },
    showPercentageSymbol: { type: Boolean, default: false },
    fontAwesomeIconName: { type: String, default: null },
    large: Boolean
  },

  computed: {
    adaptiveStyleClasses() {
      return [
        this.backgroundClass,
        this.large ? 'large' : ''
      ]
    },

    labelText() {
      return this.labels.join(" / ");
    }
  }
};
</script>

<style scoped>
.card {
  display: block;
  overflow: hidden;
}

.card.large {
  min-height: 200px
}

.icon {
  color: #fff;
}

.icon:before {
  position: absolute;
  right: 1.0rem;
  bottom: 0.5rem;

  font-size: 5rem;
  opacity: 0.2;
}

.card.large .label { font-size: 2rem; }
.card.large .material-icons
{
  font-size: 8rem;
}

span.label {
  display: block;
  line-height: 1.1em;
  min-height: 2.2em;
}
</style>